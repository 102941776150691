import React, { Fragment, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col } from 'reactstrap'
import IconGiga from '../../assets/image/giga-white.png'
import IconRobotic from '../../assets/image/logo-header.jpg'

import {  Modal,  Form, Input } from 'antd';

import { useDispatch } from 'react-redux';

import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
const gigaWhite = 'https://img.gigadigital.vn/image/1663927866720-logoGiga-white.png'

const useViewPort = () => {

  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth)
    window.addEventListener("resize", handleWindowResize)
  }, [])
  return { width }
}



const Header = () => {
  const [form] = Form.useForm();
  const [click, setClick] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const viewPort = useViewPort();
  const [maKH,setMaKH] = useState("");
  const isDesktop = viewPort.width >= 768
  const dispatch = useDispatch();
  const location = useLocation()
  const [activeLink, setActiveLink] = useState('')
  useEffect(() => {
    setActiveLink(location.pathname) // Cập nhật activeLink với đường dẫn hiện tại
  }, [location])
  return (
   
    <Fragment>
    <div className='p-0' id='top'>
      <Container fluid={true} className="search-page container">
        <div className='position-relative main-header d-flex align-items-center'>
          <div className="navbar-header d-flex align-items-center navbar-expand-md me-5">
            <Link to={'/'} className="text-gray fw-bold logo-desktop" >
              <img src={IconRobotic} className='position-relative'  alt='logo' />
            </Link>
       
            <button className="navbar-toggler" onClick={() => setClick(!click)} type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false">
              <i className="fas fa-bars text-gray fw-bold"></i>
            </button>
          </div>
          {isDesktop && (
            <div className="list-navbar-items align-items-center  navbar-collapse" id="navbarSupportedContent">
              <ul className='list-unstyled d-flex align-items-center mb-0 ' >
                <li className='me-4'> <Link to={'/'}  className={`text-gray fw-bold ${activeLink === '/' ? 'active' : ''}`}>Trang chủ</Link></li>
                <li className='me-4'> <Link to={'/kich-hoat-bao-hanh'}  className={`text-gray fw-bold ${activeLink === '/kich-hoat-bao-hanh' ? 'active' : ''}`}>Kích hoạt</Link></li>
                <li className='me-4'> <Link to={'/thoi-han-bao-hanh'} className={`text-gray fw-bold ${activeLink === '/thoi-han-bao-hanh' ? 'active' : ''}`}>Tra cứu thời hạn</Link></li>
                <li className='me-4'> <Link to={'/gui-yeu-cau'} className={`text-gray fw-bold ${activeLink === '/gui-yeu-cau' ? 'active' : ''}`}>Tạo phiếu bảo hành</Link></li>
                <li className='me-4'> <Link to={'/tra-cuu-bao-hanh'}  className={`text-gray fw-bold ${activeLink === '/tra-cuu-bao-hanh' ? 'active' : ''}`}>Tiến độ bảo hành</Link></li>
                {/* <li className='me-4'> <Link to={'/phu-kien'}  className={`text-gray fw-bold ${activeLink === '/phu-kien' ? 'active' : ''}`}>Phụ kiện</Link></li> */}
             
              </ul>
            </div>
          )}
          {
            click && (
              <div className="list-navbar-items align-items-center justify-content-between w-100  navbar-collapse 111" id="navbarSupportedContent">
                <ul className='list-unstyled d-flex align-items-center mb-0 justify-content-center'>
                  <li className='p-2'> <Link to={'/'} className='text-gray fw-bold'>Trang chủ</Link></li>
                  <li className='p-2'> <Link to={'/kich-hoat-bao-hanh'} className='text-gray fw-bold'>Kích hoạt</Link></li>
                  <li className='p-2'> <Link to={'/thoi-han-bao-hanh'} className='text-gray fw-bold'>Tra cứu thời hạn</Link></li>
                  <li className='p-2'> <Link to={'/gui-yeu-cau'} className='text-gray fw-bold'>Tạo phiếu bảo hành</Link></li>
                  {/* <li className='p-2'> <Link to={'/tram-bao-hanh'} className='text-gray fw-bold'>Liên hệ</Link></li> */}
                  <li className='p-2'> <Link to={'/tra-cuu-bao-hanh'} className='text-gray fw-bold'>Tiến độ bảo hành</Link></li>
                {/* <li className='me-4'> <Link to={'/phu-kien'}  className={`text-gray fw-bold`}>Phụ kiện</Link></li> */}

                 
                </ul>
              </div>
            )
          }
          <ul className='list-unstyled list-data d-flex align-items-center mb-0'>
          <li className='p-2 hotline text-gray fw-bold'> HOTLINE 1900.633.870</li>
          </ul>
        </div>
      </Container>
    </div>
  </Fragment>
   
  )
}

export default Header