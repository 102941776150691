import React from 'react'
import { Link } from 'react-router-dom'
import IconHopLong from '../../assets/image/logo-footer.jpg'
import IconRobotic from '../../assets/image/logo-header.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
const Footer = () => {
  return (
    <div className='footer'>
      <div className='container'>
        <div className='footer_flex'>
        <div className=''>
            <ul className='p-0 list-unstyled h-100'>
              <li><p className='mb-3'><img src={IconHopLong}s className='position-relative' style={{ width: 100, height: 60, objectFit: 'contain',backgroundColor:'transparent' }} alt='logo' />
              <img src={IconRobotic} className='position-relative' style={{ width: 200, height: 60, objectFit: 'contain',backgroundColor:'transparent' }} alt='logo' /></p></li>
              <li><p style={{fontSize:'18px'}}>Trung tâm bảo hành duy nhất <br/> <span> Ecovacs ủy quyền tại Việt Nam</span></p></li>
              <br/>
              <div className='mt-4' style={{color:'gray'}}>@ 2024 All Rights Reserved</div>
            </ul>
          </div>
          <div className=''>
            <h5 className='mb-4'><b style={{fontSize:'18px'}}>Tin tức dịch vụ</b></h5>
            <ul className='p-0 list-unstyled h-100'>
              <li><p className='mb-3'><Link to='/kich-hoat-bao-hanh'><b>Kích hoạt bảo hành</b></Link></p></li>
              <li><p className='mb-3'><Link to='/thoi-han-bao-hanh'><b>Kiểm tra thời gian bảo hành</b></Link></p></li>
              <li><p className='mb-3'><Link to='/tra-cuu-bao-hanh'><b> Kiểm tra tình trạng bảo hành</b></Link></p></li>
              <li><p><Link to='/tram-bao-hanh/ha-noi'><b> Tìm trung tâm bảo hành</b></Link></p></li>

            </ul>
          </div>
          <div className=''>
            <h5 className='mb-4'><b style={{fontSize:'18px'}}>Địa chỉ bảo hành</b></h5>
            <ul className='p-0 list-unstyled h-100'>
              <li><p className='mb-3'><b>Hà Nội </b> <br/><i class="fa fa-map-marker"></i> <Link to='/tram-bao-hanh/ha-noi'>Tòa D, Chung cư Báo Nhân dân, Xuân Phương, Nam Từ Liêm, Hà Nội</Link></p></li>
              <li><p className='mb-3'><b>Hồ Chí Minh </b><br/><i class="fa fa-map-marker"></i><Link to='/tram-bao-hanh/ho-chi-minh'> 55 Minh Phụng, Tân Thới Nhất, Quận 12, TP. Hồ Chí Minh</Link></p></li>
              <li><p ><b>Đà Nẵng </b><br/><i class="fa fa-map-marker"></i><Link to='/tram-bao-hanh/da-nang'> 35 Chu Mạnh Trinh, Khuê Trung, Cẩm Lệ, Đà Nẵng</Link></p></li>
            </ul>
          </div>
          <div className=''>
            <h5 className='mb-4'><b>Liên hệ</b></h5>
            <ul className='p-0 list-unstyled h-100'>
              <li><p className='mb-3'><i className='fa fa-phone me-2'></i><span><b>Hotline: </b><a href="tel:+1900633870">1900.633.870</a></span></p></li>
              <li><p><i className='fa fa-envelope me-2'></i><b>Email: </b><span>info@giga.vn </span></p></li>
            </ul>
          </div>
         
        </div>
        
       
      </div>
      
    </div>
  )
}

export default Footer